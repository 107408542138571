@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  margin: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  margin-top: 4rem;
  position: relative;
}

.text-center {
  text-align: center;
}

.title {
  font-size: 18px !important;
}

.bold {
  font-weight: bold;
}

.full-width {
  width: 100%
}

.facebook-icon:hover {
  background-color: #2851A3;
  border: 1px solid #2851A3;
}

.twitter-icon:hover {
  background-color: #1DA1F2;
  border: 1px solid #1DA1F2;
}

.linkedin-icon:hover {
  background-color: #0a66c2;
  border: 1px solid #0a66c2;
}

.instagram-icon:hover {
  background-color: #E4405F;
  border: 1px solid #E4405F;
}

.icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: color 0.3s;
  background-color: #9a0000;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: .5rem;
}

.icons div {
  padding: .5rem .2rem;
}

.icons {
  display: flex;
  flex-wrap: wrap;
}

hr {
  border: 1px solid #ddd;
  margin-top: 2rem;
  width: 95%;
}

.margin-forty {
  margin-top: 40px;
}

.margin-zero {
  margin: 0;
}

.section-title {
  text-align: center;
  font-size: 32px;
  font-family: Cambria;
  font-weight: bold;
  font-style: italic;
  color: #000;
  padding: 0 0 .5rem;
  margin: 0;
}

.section-title p {
  padding: 0;
  margin: 0;
}

.section {
  padding: 2.1875rem 0;
  overflow: hidden;
}

.item-slide {
  text-align: center;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-slide .item {
  width: 100%; 
  padding: 1rem;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  transition: transform 0.3s ease;
}

.item-slide .item .item-image {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.item-slide .item .item-image img {
  border-radius: 8px;
  transition: 0.5s;
  width: 240px;
  height: 150px;
  max-width: 100%;
  object-fit: contain;
}

.item-slide .item button{
  margin-top: 2rem;
}

.item-slide .item span {
  border-radius: 10rem;
  text-align: center;
  width: auto;
  min-width: 4rem;
  font-size: 14px;
  margin: 5px;
  padding: 3px 15px;
  color: #9a0000;
  background-color: rgb(229 169 169 / 50%);
}

.item-slide .item .title {
  font-size: 19px;
  color: #000;
  font-weight: 600;
  padding: 10px 0 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.item-slide .item .description {
  font-size: 15px;
  color: #808080;
  margin: 0 0 10px;
}

.item-slide .item .offer-footer {
  width: 100%;
  min-height: 6vh;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.item-slide:hover .item-image img {
  transform: scale(1.1);
}

.item-slide:hover .item {
  transform: scale(1.03);
}

.item-slide .home-banner-item-actions .overflow-menu {
  margin: 0 !important;
}

.home-banner-item-actions {
  position: absolute;
  padding: 10px;
  display: flex;
  width: 100%;
  height: auto;
  justify-content: flex-end;
}

.home-banner-item-actions svg {
  margin: 0 .25rem;
  color: #ddd;
  font-size: 18px;
}

.home-banner-item-actions svg.btn-icon {
  padding: .5rem;
}

.home-banner-item-actions .overflow-menu {
  margin: .5rem 2rem;
}

.slick-next::after {
  content: '→';
  color: red;
  font-family: 'slick';
  font-size: 32px;
  line-height: 1;
  opacity: .75;
  -webkit-font-smoothing: antialiased;
  z-index: 1;
}

.slick-next:before {
position: absolute;
display: none;
}

.slick-prev:before {
color: red !important;
font-size: 32px !important;
}

.slick-arrow.slick-next{
position: absolute;
right: 10px;
z-index: 2; 
}

.slick-arrow.slick-prev{
position: absolute;
left: 4px;
z-index: 2;
}

.slick-next.slick-disabled:after {
  opacity: .25 !important;
}
.slick-next.slick-disabled::before {
  opacity: .25 !important;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:after, .slick-next:focus:after {
  opacity: 1;
}

.product-add-slider-container {
margin-bottom: 2rem;
}