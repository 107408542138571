.main-footer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    background: #9a0000;
    justify-content: center;
    align-items: start;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    min-height: 60vh;
    padding: 2.5rem;
}

.main-footer .footer-about-image-container {
    width: 40%;
    align-self: center;
}

.main-footer .footer-about-image-container img {
    width: 100%;
}


.footer-upper-container {
    flex-basis: 33.33%;
}

/* Media query for devices with maximum width 768px */
@media (max-width: 768px) {
    footer {
        flex-direction: column;
        /* Change to vertical layout */
    }

    .footer-upper-container {
        flex-basis: 100%;
        /* Take up full width on devices */
        display: flex;
        flex-direction: column;
        margin: 1rem
    }
}

.footer-upper-container .contact-info-content {
    padding: .25rem;
}

.footer-upper-container p {
    margin: 0;
    padding: 0;
    color: #f8df00;
    font-size: 14px;
}

.footer-upper-container ul li {
    margin: .6rem;
}

.footer-upper-container ul li a {
    color: #f8df00;
    font-size: 14px;
}

.footer-lower-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-lower-container>p {
    font-size: 14px;
    color: #ddd;
    margin: 0;
}