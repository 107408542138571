.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links.default a:hover,
.nav-links.default a.active {
  border-bottom: 2px solid #f8df00;
  color: #f8df00;
  transition: color, font-size 0.3s ease;
}

.nav-links.default button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links.default button:focus {
  outline: none;
}

@media (min-width: 868px) {
  .nav-links.default {
    flex-direction: row;
  }

  .nav-links.default li {
    margin: 0 0.5rem;
  }

  .nav-links.default a {
    color: white;
    text-decoration: none;
    user-select: none;
  }

  .nav-links.default button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }
}

.nav-links.footer-links {
  display: flex;
  flex-direction: column;
}

.logout-btn:hover {
  background-color: rgb(241, 33, 33) !important;
  color: white !important;
}
