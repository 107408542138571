.categories-skeleton-container {
  margin: 1.5rem 7.5%;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.categories-skeleton-container::-webkit-scrollbar {
  display: none;
}

.categories-skeleton {
  flex-shrink: 0;
  min-height: 6rem;
  width: 200px;
  background-color: #535050;
  margin-right: 16px;
  border-radius: 4px;
  background: linear-gradient(to right, #bbbaba, #e3e1e1 50%, #bbbaba);
  background-size: 200% 100%;
  animation: skeleton-loading 3s linear infinite;
}

/* Category Products skeleton && Gallery Products skeleton */
.categories-products-skeleton-container,
.gallery-products-skeleton-container {
  margin: 1rem 7.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-about-image-skeleton,
.home-about-title-skeleton,
.home-about-description-skeleton,
.home-about-button-skeleton,
.home-carousel-skeleton-container,
.banner-skeleton-container,
.categories-products-skeleton,
.gallery-products-skeleton,
.product-title-skeleton,
.product-description-skeleton,
.product-description2-skeleton,
.section-title-skeleton,
.section-picker-skeleton,
.section-available-skeleton,
.section-submit-skeleton,
.feedback-title-skeleton,
.feedback-add-skeleton,
.section-subtitle-skeleton,
.contact-title-skeleton,
.contact-subtitle-skeleton,
.contact-long-skeleton,
.contact-short-skeleton,
.footer-contact-text-skeleton
 {
   background-color: #535050;
   background: linear-gradient(to right, #bbbaba, #e3e1e1 50%, #bbbaba);
   background-size: 200% 100%;
   animation: skeleton-loading 3s linear infinite;
 }

.categories-products-skeleton {
  min-height: 5rem;
  margin: .5rem 0;
  width: 100%;
  margin-right: 16px;
  border-radius: 4px;
}

.gallery-products-skeleton {
  min-height: 20rem;
  margin: .5rem 0;
  width: 100%;
  margin-right: 16px;
  border-radius: 4px;
}

/* End Category Products skeleton && Gallery Products skeleton */

/* Start home About skeleton */
.home-about-skeleton-container {
  padding: 4.375rem 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.home-about-details-skeleton {
  flex-basis: 40%;
}

.home-about-image-skeleton {
  border-radius: 10px;
  flex-basis: 30%;
  min-height: 20rem;

}

.home-about-description-skeleton {
  min-height: 2rem;
  margin: 1rem;
  border-radius: 40px;
  margin-right: 16px;
  width: 100%;
}

.home-about-description-skeleton:nth-of-type(4) {
  width: 80%;
}

.home-about-title-skeleton {
  min-height: 2rem;
  margin: 1rem;
  border-radius: 40px;
  margin-right: 16px;
  width: 50%;
}

.home-about-button-skeleton {
  min-height: 3rem;
  margin: 2rem;
  border-radius: 30px;
  margin-right: 16px;
  width: 40%;
}

@media (max-width: 912px) {
  .home-about-image-skeleton {
    flex-basis: 35%;
  }
}

@media (max-width: 712px) {
  .home-about-image-skeleton {
    flex-basis: 70%;
  }

  .home-about-details-skeleton {
    flex-basis: 70%;
  }
}

@media (max-width: 512px) {
  .home-about-image-skeleton {
    flex-basis: 80%;
  }

  .home-about-details-skeleton {
    flex-basis: 80%;
  }
}
/* End home About skeleton */


/* Start product section skeleton */
.product-skeleton-container {
  padding: 4.375rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.product-details-skeleton {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-basis: 90%;
}

.product-title-skeleton {
  min-height: 2rem;
  margin: .5rem 1rem;
  border-radius: 40px;
  width: 35%;
}

.product-description-skeleton {
  min-height: 1.5rem;
  margin: .5rem 1rem;
  border-radius: 40px;
  width: 70%;
}

.product-description2-skeleton {
  min-height: 1.5rem;
  margin: .5rem 1rem;
  border-radius: 40px;
  width: 100%;
}

.product-description2-skeleton:nth-of-type(5) {
  width: 60%;
}

.product-description2-skeleton:nth-of-type(3) {
  margin-top: 3rem
}

.product-skeleton-wrapper .categories-skeleton-container {
  margin-top: 2rem;
}
/* End product section skeleton */


/* Start Home Gallery Admin Skeleton */
.gallery-home-admin-container, .feedback-home-admin-container {
  margin-bottom: 1rem;
  /* padding: 4.375rem 0 0; */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.section-wrap-skeleton {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  flex-basis: 50%;
}

.section-title-skeleton {
  min-height: 2.5rem;
  margin: .5rem 1rem;
  width: 80%;
}

.section-subtitle-skeleton {
  min-height: 2rem;
  margin: .5rem 1rem;
  width: 80%;
}

.section-picker-skeleton {
  min-height: 13rem;
  margin: 0.5rem 0 0.5rem 4rem;
  width: 50%;
  align-self: flex-start;
}

.section-available-skeleton {
  min-height: 1.5rem;
  margin: 1rem;
  border-radius: 40px;
  margin-right: 16px;
  width: 80%;
}

.section-submit-skeleton {
  min-height: 2.5rem;
  margin: 1rem 10rem 0.5rem 0rem;
  border-radius: 30px;
  width: 35%;
}

@media (max-width: 712px) {
  .section-wrap-skeleton, .feedback-upper-container, .contact-upper-container {
    flex-basis: 60%;
  }
}

@media (max-width: 512px) {
  .section-wrap-skeleton, .feedback-upper-container, .contact-upper-container {
    flex-basis: 100%;
  }

  .section-title-skeleton, 
  .feedback-title-skeleton, 
  .section-subtitle-skeleton, 
  .contact-title-skeleton,
  .contact-subtitle-skeleton
  {
    width: 60% !important;
  }

  .feedback-add-skeleton {
    width: 40% !important;
  }
  
  .section-picker-skeleton {
    align-self: normal;
    margin: 0.5rem 0 0.5rem 6rem;
    width: 55%;
  }
  
  .section-available-skeleton {
    
    width: 60%;
  }
  
  .section-submit-skeleton {
    margin: 1rem 5rem 0.5rem 0rem;
    width: 35%;
  }
}

@media (max-width: 320px) {
  .section-wrap-skeleton, .feedback-upper-container, .contact-upper-container {
    flex-basis: 100%;
  }

  .section-title-skeleton, 
  .feedback-title-skeleton, 
  .section-subtitle-skeleton, 
  .contact-title-skeleton,
  .contact-subtitle-skeleton
  {
    width: 80% !important;
  }

  .feedback-add-skeleton {
    width: 50% !important;
  }
  
  .section-picker-skeleton {
    margin: 0.5rem 2rem;
    width: 80%;
  }
  
  .section-available-skeleton {
    width: 80%;
  }
  
  .section-submit-skeleton {
    margin: 1rem 5rem 0.5rem 0rem;
    width: 50%;
  }
}
/* End Home Gallery Admin Skeleton */

/* Start Feedback Home Skeleton */
.feedback-home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feedback-upper-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  flex-basis: 50%;
}

.feedback-title-skeleton {
  min-height: 2.5rem;
  margin: .5rem 1rem;
  border-radius: 40px;
  width: 40%;
}

.feedback-add-skeleton {
  min-height: 2.5rem;
  margin: .5rem 1rem;
  width: 20%;
  border-radius: 4px;
}

.feedback-items-container {
  margin: 1.5rem 7.5%;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.feedback-items-container::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.feedback-item-skeleton {
  flex-shrink: 0;
  min-height: 6rem;
  width: 50%;
  background-color: #535050;
  margin-right: 16px;
  border-radius: 4px;
  background: linear-gradient(to right, #bbbaba, #e3e1e1 50%, #bbbaba);
  background-size: 200% 100%;
  animation: skeleton-loading 3s linear infinite;
}

@media (max-width: 768px) {
  .feedback-item-skeleton {
    width: 100% !important;
  }
  .feedback-item-skeleton.hidden {
    display: none;
  }
}
/* End Feedback Home Skeleton */

/* Start Contact Section Skeleton */
.contact-section-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-home-admin-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-upper-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  flex-basis: 50%;
}

.contact-title-skeleton {
  min-height: 2.5rem;
  margin: .5rem 1rem;
  border-radius: 40px;
  width: 40%;
}

.contact-subtitle-skeleton {
  min-height: 1.5rem;
  margin: .5rem 1rem;
  border-radius: 40px;
  width: 60%;
}

.contact-bottom-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  -ms-overflow-style: none;
  flex-wrap: wrap;
  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.contact-bottom-container::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.contact-long-skeleton {
  min-height: 2.5rem;
  margin: .5rem 1rem;
  border-radius: 40px;
  width: 100%;
}

.contact-short-skeleton {
  min-height: 2.5rem;
  margin: .5rem 1rem;
  border-radius: 40px;
  width: 60%;
}

.contact-left-container {
  flex-basis: 45%;
}

.contact-right-container {
  flex-basis: 45%;
}

.contact-wrap-container {
  padding: 1rem 2rem 1rem 0;
}

@media (max-width: 812px) {
  .contact-left-container {
    flex-basis: 90%;
  }
  
  .contact-right-container {
    flex-basis: 90%;
  }
}
/* End Contact Section Skeleton */

/* Start Main Footer Skeleton */
.main-footer-skeleton-container {
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 33.33%;
}

.footer-contact-wrap-skeleton {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  flex-basis: 100%;
}

.footer-contact-text-skeleton {
  min-height: 1.5rem;
  margin: 1rem;
  border-radius: 40px;
  width: 45%;
}

.full-width {
  width: 90% !important;
}

@media (max-width: 768px) {
  .main-footer-skeleton-container {
    flex-basis: 100%;
  }
}
/* End Main Footer Skeleton */



/* Banner Skeleton */
.banner-skeleton-container {
  height: 50vh;
  margin-bottom: 2.1875rem;
}

@media (min-width: 768px) {
  .home-carousel-skeleton-container {
    height: 35rem;
  }
}

@media (max-width: 768px) and (min-width:444px) {
  .home-carousel-skeleton-container {
    height: 25rem;
  }
}

@media (max-width: 444px) and (min-width:1px) {
  .home-carousel-skeleton-container {
    height: 15rem;
  }
}

/* End Banner Skeleton */

@keyframes skeleton-loading {
  0% {
    background-position: 200% 50%;
  }

  100% {
    background-position: -200% 50%;
  }
}