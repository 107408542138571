.whatsapp-container {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 99999999;
}

.whatsapp-container .whatsapp-icon {
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #25D366;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    padding: 0.5rem;
    animation: whatsapp-ring 2s infinite alternate;
}

.whatsapp-container .vibration {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 100px;
    height: 5px;
}

.vibration span {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-right: 3px solid rgba(161, 153, 153, 0.7);
    transform: rotate(-30deg);
    border-radius: 100%;
    animation: curve-animation 1.5s infinite cubic-bezier(0.5, 0, 0.5, 1);
}

.vibration span:nth-child(1) {
    animation-delay: 0.6s;
}

.vibration span:nth-child(2) {
    animation-delay: 0.3s;
}

@keyframes whatsapp-ring {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes curve-animation {
    0% {
        transform: translateY(10px) translateX(15px) rotate(-30deg);
        opacity: 1;
    }

    25% {
        transform: translateY(5px) translateX(20px) rotate(-30deg);
        opacity: 1;
    }

    50% {
        transform: translateY(0) translateX(25px) rotate(-30deg);
        opacity: 1;
    }

    50% {
        transform: translateY(-5px) translateX(30px) rotate(-30deg);
        opacity: 1;
    }

    100% {
        transform: translateY(-10px) translateX(35px) rotate(-30deg);
        opacity: 0;
    }
}